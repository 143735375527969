<template>
  <div class="page-wrapper">
    <TheSidebar />
    <div class="right-content-wrapper">
      <v-form
        ref="form"
        @submit.prevent="save()"
        v-model="valid"
        action="/"
        v-if="!spinnerActive"
      >
        <div style="height: calc(100vh - 50px);">
          <v-card v-if="about_us_content">
            <v-card-title>
              <span class="text-h5">About Us page</span>
            </v-card-title>
            <v-form
              ref="form"
              @submit.prevent="save()"
              action="/"
            >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-text-field
                      :rules="[rules.required]"
                      v-model="about_us_content.title"
                      label="About Us title"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <span style="width: 100%" class="mt-8">Description</span>
                      <editor
                        style="width: 100%"
                        v-if="editorReady"
                        v-model="about_us_content.description"
                        api-key="2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb"
                        :disabled="false"
                        initial-value=""
                        :init="{
                          images_upload_url: `${baseURL}/api/image-upload`,
                          height: 500,
                          menubar: false,
                          document_base_url: `${baseURL}/`,
                          relative_urls: false,
                          remove_script_host: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help \
                            link \
                            media image',
                        }"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-file-input
                        accept="image/* //"
                        :rules="[!!right_image || 'Required.', rules.size]"
                        placeholder="Pick an image"
                        v-model="about_us_content.right_image"
                        :input="loadRightImage()"
                        label="Image"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                      </v-file-input>
                      <div style="width: 100%">
                        <img
                          v-if="right_image"
                          :src="getImage(right_image)"
                          height="100"
                          class="mb-4"
                        />
                      </div>
                      <div style="width: 100%">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-on="on"
                              v-model="about_us_content.right_alt_image"
                              label="SEO - Right alt image"
                            ></v-text-field>
                          </template>
                          <span>The Alt tags are used to describe the image to
                          <br>search engines (cannot be seen by the user).
                          <br>Try to create a simple description of the image
                          <br>as if you were describing it to someone with their eyes closed.
                          </span>
                        </v-tooltip>
                      </div>
                      <v-textarea
                        v-model="about_us_content.right_description_1"
                        label="Orange badge text"
                      ></v-textarea>
                      <v-text-field
                        v-model="about_us_content.right_url"
                        label="Orange badge URL"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-file-input
                        accept="image/* //"
                        :rules="[!!left_image_1 || 'Required.', rules.size]"
                        placeholder="Pick an image"
                        v-model="about_us_content.left_image_1"
                        :input="loadLeftImage_1()"
                        label="Image"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                      </v-file-input>
                      <div style="width: 100%">
                        <img
                          v-if="left_image_1"
                          :src="getImage(left_image_1)"
                          height="100"
                          class="mb-4"
                        />
                      </div>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            v-model="about_us_content.left_alt_image_1"
                            label="SEO - Left image 1 alt"
                          ></v-text-field>
                        </template>
                        <span>The Alt tags are used to describe the image to
                        <br>search engines (cannot be seen by the user).
                        <br>Try to create a simple description of the image
                        <br>as if you were describing it to someone with their eyes closed.
                        </span>
                      </v-tooltip>
                      <v-text-field
                        v-model="about_us_content.left_image_title_1"
                        label="Left image 1 title"
                      ></v-text-field>
                      <!-- <v-text-field
                        v-model="about_us_content.left_image_url_1"
                        label="Left image 1 URL"
                      ></v-text-field> -->
                      <v-file-input
                        accept="image/* //"
                        :rules="[!!left_image_2 || 'Required.', rules.size]"
                        placeholder="Pick an image"
                        v-model="about_us_content.left_image_2"
                        :input="loadLeftImage_2()"
                        label="Image"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                      </v-file-input>
                      <div style="width: 100%">
                        <img
                          v-if="left_image_2"
                          :src="getImage(left_image_2)"
                          height="100"
                          class="mb-4"
                        />
                      </div>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            v-model="about_us_content.left_alt_image_2"
                            label="SEO - Left image 2 alt"
                          ></v-text-field>
                        </template>
                        <span>The Alt tags are used to describe the image to
                        <br>search engines (cannot be seen by the user).
                        <br>Try to create a simple description of the image
                        <br>as if you were describing it to someone with their eyes closed.
                        </span>
                      </v-tooltip>
                      <v-text-field
                        v-model="about_us_content.left_image_title_2"
                        label="Left image 2 title"
                      ></v-text-field>
                      <!-- <v-text-field
                        v-model="about_us_content.left_image_url_2"
                        label="Left image 2 URL"
                      ></v-text-field> -->
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="about_us_content.right_title_2"
                        label="Title"
                      ></v-text-field>
                      <editor
                        style="width: 100%"
                        v-if="editorReady"
                        v-model="about_us_content.right_description_2"
                        api-key="2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb"
                        :disabled="false"
                        initial-value=""
                        :init="{
                          images_upload_url: `${baseURL}/api/image-upload`,
                          height: 500,
                          menubar: false,
                          document_base_url: `${baseURL}/`,
                          relative_urls: false,
                          remove_script_host: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help \
                            link \
                            media image',
                        }"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="about_us_content.section_1_title"
                      label="Title"
                    ></v-text-field>
                    <editor
                      style="width: 100%"
                      v-if="editorReady"
                      v-model="about_us_content.section_1_description"
                      api-key="2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb"
                      :disabled="false"
                      initial-value=""
                      :init="{
                        images_upload_url: `${baseURL}/api/image-upload`,
                        height: 500,
                        menubar: false,
                        document_base_url: `${baseURL}/`,
                        relative_urls: false,
                        remove_script_host: true,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount',
                        ],
                        toolbar:
                          'undo redo | formatselect | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help \
                          link \
                          media image',
                      }"
                    />
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-file-input
                        accept="image/* //"
                        :rules="[!!left_image_3 || 'Required.', rules.size]"
                        placeholder="Pick an image"
                        v-model="about_us_content.left_image_3"
                        :input="loadLeftImage_3()"
                        label="Image"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                      </v-file-input>
                      <div style="width: 100%">
                        <img
                          v-if="left_image_3"
                          :src="getImage(left_image_3)"
                          height="100"
                          class="mb-4"
                        />
                      </div>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            v-model="about_us_content.left_alt_image_3"
                            label="SEO - Left image 3 alt"
                          ></v-text-field>
                        </template>
                        <span>The Alt tags are used to describe the image to
                        <br>search engines (cannot be seen by the user).
                        <br>Try to create a simple description of the image
                        <br>as if you were describing it to someone with their eyes closed.
                        </span>
                      </v-tooltip>
                      <v-textarea
                        v-model="about_us_content.left_description_1"
                        label="Blue badge text"
                      ></v-textarea>
                      <v-text-field
                        v-model="about_us_content.blue_badge_url"
                        label="Blue badge URL"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <editor
                        style="width: 100%"
                        v-if="editorReady"
                        v-model="about_us_content.right_description_3"
                        api-key="2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb"
                        :disabled="false"
                        initial-value=""
                        :init="{
                          images_upload_url: `${baseURL}/api/image-upload`,
                          height: 500,
                          menubar: false,
                          document_base_url: `${baseURL}/`,
                          relative_urls: false,
                          remove_script_host: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help \
                            link \
                            media image',
                        }"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="about_us_content.vimeo_url"
                      label="Vimeo link (video ID only - https://vimeo.com/518793614 -> 518793614)"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="about_us_content.left_title"
                      label="Title"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <editor
                        style="width: 100%"
                        v-if="editorReady"
                        v-model="about_us_content.left_description_2"
                        api-key="2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb"
                        :disabled="false"
                        initial-value=""
                        :init="{
                          images_upload_url: `${baseURL}/api/image-upload`,
                          height: 500,
                          menubar: false,
                          document_base_url: `${baseURL}/`,
                          relative_urls: false,
                          remove_script_host: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help \
                            link \
                            media image',
                        }"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-file-input
                        accept="image/* //"
                        :rules="[!!image_down || 'Required.', rules.size]"
                        placeholder="Pick an image"
                        v-model="about_us_content.image_down"
                        :input="loadDownImage()"
                        label="Image"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                      </v-file-input>
                      <div style="width: 100%">
                        <img
                          v-if="image_down"
                          :src="getImage(image_down)"
                          height="100"
                          class="mb-4"
                        />
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="about_us_content.tab_title"
                          label="SEO - Page title (shown in browser tab)"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <span>SEO Page title - This is the title of the tab of the page and also shows up
                        <br>in search engines as the main title for the page.
                        <br>Keep the title short (max. 60 characters) and
                        <br>utilise keywords from the page.
                      </span>
                    </v-tooltip>
                  </v-row>
                  <v-row>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                      <v-textarea
                        v-model="about_us_content.og_meta_desc"
                        v-bind="attrs"
                        v-on="on"
                        label="SEO - Meta description (this description will appear on google search results)"
                      ></v-textarea>
                      </template>
                      <span>The meta description is shown in search engines and
                        <br>is used to describe the contents of the page.
                        <br>Keep it between 50-160 characters and try to
                        <br>imagine what a user would search for in order
                        <br>to find the contents of the page.
                      </span>
                    </v-tooltip>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn color="blue darken-1" text @click="closeMedicalAffairsDialog">Cancel</v-btn> -->
                <span v-if="successfullyUpdated" style="color:green; margin-right:10px">Successfully updated!</span>
                <v-btn color="blue darken-1" text type="submit">Save</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { fetchAboutUsPage, updateAboutUsPage } from "@/services";
import TheSidebar from "@/components/TheSidebar"

export default {
  name: "AboutUsPage",
  components: {
    TheSidebar,
  },
  data () {
    return {
      successfullyUpdated: false,
      about_us_content: null,
      spinnerActive: false,
      valid: false,
      editorReady: true,
      baseURL: '',
      left_image_1: null,
      left_image_2: null,
      left_image_3: null,
      right_image: null,
      image_down: null,
      fileName_left_image_1: null,
      fileName_left_image_2: null,
      fileName_left_image_3: null,
      fileName_right_image: null,
      fileName_image_down: null,
      updated_left_image_1: null,
      updated_left_image_2: null,
      updated_left_image_3: null,
      updated_right_image: null,
      updated_image_down: null,
      rules: {
        required: (v) => !!v || "Required.",
        requireLength: (v) => (v != undefined && v.length > 0) || "Required",
        sizes: (files) =>
          !files ||
          !files.some((file) => file.size > 20e6) ||
          "Image size should be less than 1 MB!",
        size: (file) =>
          !file || file.size < 20e6 || "Image size should be less than 20 MB!",
        slug: (v) =>
          /^[a-z0-9-]+$/gm.test(v) ||
          "Must be lowercase and with '-' instead of 'whitespace'.",
      },
    }
  },
  mounted() {
    this.baseURL = window.location.origin === 'http://localhost:8080' ? 'http://stage.cor2ed.xyz' : window.location.origin
    // this.getItems()
    this.getAboutUsPage()
  },
  methods: {
    async getAboutUsPage() {
      await fetchAboutUsPage().then(response => {
        console.log('response', response)
        this.about_us_content = response.data.data
        if (this.about_us_content.left_image_1) {
          this.left_image_1 = this.about_us_content.left_image_1
          this.about_us_content.left_image_1 = null 
        }
        if (this.about_us_content.left_image_2) {
          this.left_image_2 = this.about_us_content.left_image_2
          this.about_us_content.left_image_2 = null 
        }
        if (this.about_us_content.left_image_3) {
          this.left_image_3 = this.about_us_content.left_image_3
          this.about_us_content.left_image_3 = null 
        }
        if (this.about_us_content.right_image) {
          this.right_image = this.about_us_content.right_image
          this.about_us_content.right_image = null 
        }
        if (this.about_us_content.image_down) {
          this.image_down = this.about_us_content.image_down
          this.about_us_content.image_down = null 
        }
      }).catch(error => {
        console.log('error', error)
      })
    },
    loadRightImage() {
      if (this.about_us_content && this.about_us_content.right_image && this.about_us_content.right_image.size) {
        var file = this.about_us_content.right_image
        var reader = new FileReader()
        this.fileName_right_image = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.right_image = reader.result
            this.updated_right_image = true
            this.about_us_content.right_image = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    loadLeftImage_1() {
      if (this.about_us_content && this.about_us_content.left_image_1 && this.about_us_content.left_image_1.size) {
        var file = this.about_us_content.left_image_1
        var reader = new FileReader()
        this.fileName_left_image_1 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.left_image_1 = reader.result
            this.updated_left_image_1 = true
            this.about_us_content.left_image_1 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    loadLeftImage_2() {
      if (this.about_us_content && this.about_us_content.left_image_2 && this.about_us_content.left_image_2.size) {
        var file = this.about_us_content.left_image_2
        var reader = new FileReader()
        this.fileName_left_image_2 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.left_image_2 = reader.result
            this.updated_left_image_2 = true
            this.about_us_content.left_image_2 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    loadLeftImage_3() {
      console.log('loadLeftImage_3')
      if (this.about_us_content && this.about_us_content.left_image_3 && this.about_us_content.left_image_3.size) {
        var file = this.about_us_content.left_image_3
        var reader = new FileReader()
        this.fileName_left_image_3 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.left_image_3 = reader.result
            this.updated_left_image_3 = true
            this.about_us_content.left_image_3 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    loadDownImage() {
      console.log('loadDownImage')
      if (this.about_us_content && this.about_us_content.image_down && this.about_us_content.image_down.size) {
        var file = this.about_us_content.image_down
        var reader = new FileReader()
        this.fileName_image_down = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.image_down = reader.result
            this.updated_image_down = true
            this.about_us_content.image_down = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    // medicalAffairsLoadImage_2() {
    //   if (this.about_us_content && this.about_us_content.about_us_image_2 && this.about_us_content.about_us_image_2.size) {
    //     var file = this.about_us_content.about_us_image_2
    //     var reader = new FileReader()
    //     this.fileName_ma_2 = file.name
    //     reader.onloadend = async () => {
    //       if (reader.result.length * (3 / 4) < 20e6) {
    //         this.about_us_image_2 = reader.result
    //         this.imageUpdated_ma_2 = true
    //         this.about_us_content.about_us_image_2 = null
    //       }
    //     }
    //     reader.readAsDataURL(file)
    //   }
    // },
    // medicalAffairsLoadImage_3() {
    //   if (this.about_us_content && this.about_us_content.about_us_image_3 && this.about_us_content.about_us_image_3.size) {
    //     var file = this.about_us_content.about_us_image_3
    //     var reader = new FileReader()
    //     this.fileName_ma_3 = file.name
    //     reader.onloadend = async () => {
    //       if (reader.result.length * (3 / 4) < 20e6) {
    //         this.about_us_image_3 = reader.result
    //         this.imageUpdated_ma_3 = true
    //         this.about_us_content.about_us_image_3 = null
    //       }
    //     }
    //     reader.readAsDataURL(file)
    //   }
    // },
    // medicalAffairsLoadImage_4() {
    //   if (this.about_us_content && this.about_us_content.about_us_image_4 && this.about_us_content.about_us_image_4.size) {
    //     var file = this.about_us_content.about_us_image_4
    //     var reader = new FileReader()
    //     this.fileName_ma_4 = file.name
    //     reader.onloadend = async () => {
    //       if (reader.result.length * (3 / 4) < 20e6) {
    //         this.about_us_image_4 = reader.result
    //         this.imageUpdated_ma_4 = true
    //         this.about_us_content.about_us_image_4 = null
    //       }
    //     }
    //     reader.readAsDataURL(file)
    //   }
    // },
    // medicalAffairsLoadImage_5() {
    //   if (this.about_us_content && this.about_us_content.about_us_image_5 && this.about_us_content.about_us_image_5.size) {
    //     var file = this.about_us_content.about_us_image_5
    //     var reader = new FileReader()
    //     this.fileName_ma_5 = file.name
    //     reader.onloadend = async () => {
    //       if (reader.result.length * (3 / 4) < 20e6) {
    //         this.about_us_image_5 = reader.result
    //         this.imageUpdated_ma_5 = true
    //         this.about_us_content.about_us_image_5 = null
    //       }
    //     }
    //     reader.readAsDataURL(file)
    //   }
    // },
    // medicalAffairsLoadImage_6() {
    //   if (this.about_us_content && this.about_us_content.about_us_image_6 && this.about_us_content.about_us_image_6.size) {
    //     var file = this.about_us_content.about_us_image_6
    //     var reader = new FileReader()
    //     this.fileName_ma_6 = file.name
    //     reader.onloadend = async () => {
    //       if (reader.result.length * (3 / 4) < 20e6) {
    //         this.about_us_image_6 = reader.result
    //         this.imageUpdated_ma_6 = true
    //         this.about_us_content.about_us_image_6 = null
    //       }
    //     }
    //     reader.readAsDataURL(file)
    //   }
    // },
    getImage(imageName) {
      if (imageName && imageName.includes("data:image")) {
        return imageName  
      } else {
        return window.location.origin === 'http://localhost:8080' ? 'http://stage.cor2ed.xyz/api/image/' + imageName : window.location.origin + '/api/image/' + imageName
      }
    },
    async save() {
      let data = Object.assign({}, this.about_us_content)
      if (this.left_image_1) {
        data.left_image_1 = this.left_image_1
        data["file_name_2"] = this.fileName_left_image_1
      }
      if (this.left_image_2) {
        data.left_image_2 = this.left_image_2
        data["file_name_3"] = this.fileName_left_image_2
      }
      if (this.left_image_3) {
        data.left_image_3 = this.left_image_3
        data["file_name_4"] = this.fileName_left_image_3
      }
      if (this.right_image) {
        data.right_image = this.right_image
        data["file_name_1"] = this.fileName_right_image
      }
      if (this.image_down) {
        data.image_down = this.image_down
        data["file_name_5"] = this.fileName_image_down
      }
      
      await updateAboutUsPage(data).then(() => {
        this.successfullyUpdatedTrigger()
        this.getAboutUsPage()
      })
    },
    successfullyUpdatedTrigger() {
      this.successfullyUpdated = true
      setTimeout(() => {
        this.successfullyUpdated = false
      }, 5000);
    }
  }
}
</script>
<style scoped>
.save-button-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>