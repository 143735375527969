var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrapper"},[_c('TheSidebar'),_c('div',{staticClass:"right-content-wrapper"},[(!_vm.spinnerActive)?_c('v-form',{ref:"form",attrs:{"action":"/"},on:{"submit":function($event){$event.preventDefault();return _vm.save()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticStyle:{"height":"calc(100vh - 50px)"}},[(_vm.about_us_content)?_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("About Us page")])]),_c('v-form',{ref:"form",attrs:{"action":"/"},on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"label":"About Us title"},model:{value:(_vm.about_us_content.title),callback:function ($$v) {_vm.$set(_vm.about_us_content, "title", $$v)},expression:"about_us_content.title"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"mt-8",staticStyle:{"width":"100%"}},[_vm._v("Description")]),(_vm.editorReady)?_c('editor',{staticStyle:{"width":"100%"},attrs:{"api-key":"2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb","disabled":false,"initial-value":"","init":{
                        images_upload_url: (_vm.baseURL + "/api/image-upload"),
                        height: 500,
                        menubar: false,
                        document_base_url: (_vm.baseURL + "/"),
                        relative_urls: false,
                        remove_script_host: true,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount' ],
                        toolbar:
                          'undo redo | formatselect | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help \
                          link \
                          media image',
                      }},model:{value:(_vm.about_us_content.description),callback:function ($$v) {_vm.$set(_vm.about_us_content, "description", $$v)},expression:"about_us_content.description"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-file-input',{attrs:{"accept":"image/* //","rules":[!!_vm.right_image || 'Required.', _vm.rules.size],"placeholder":"Pick an image","input":_vm.loadRightImage(),"label":"Image","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},model:{value:(_vm.about_us_content.right_image),callback:function ($$v) {_vm.$set(_vm.about_us_content, "right_image", $$v)},expression:"about_us_content.right_image"}}),_c('div',{staticStyle:{"width":"100%"}},[(_vm.right_image)?_c('img',{staticClass:"mb-4",attrs:{"src":_vm.getImage(_vm.right_image),"height":"100"}}):_vm._e()]),_c('div',{staticStyle:{"width":"100%"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"SEO - Right alt image"},model:{value:(_vm.about_us_content.right_alt_image),callback:function ($$v) {_vm.$set(_vm.about_us_content, "right_alt_image", $$v)},expression:"about_us_content.right_alt_image"}},'v-text-field',attrs,false),on))]}}],null,false,2332556541)},[_c('span',[_vm._v("The Alt tags are used to describe the image to "),_c('br'),_vm._v("search engines (cannot be seen by the user). "),_c('br'),_vm._v("Try to create a simple description of the image "),_c('br'),_vm._v("as if you were describing it to someone with their eyes closed. ")])])],1),_c('v-textarea',{attrs:{"label":"Orange badge text"},model:{value:(_vm.about_us_content.right_description_1),callback:function ($$v) {_vm.$set(_vm.about_us_content, "right_description_1", $$v)},expression:"about_us_content.right_description_1"}}),_c('v-text-field',{attrs:{"label":"Orange badge URL"},model:{value:(_vm.about_us_content.right_url),callback:function ($$v) {_vm.$set(_vm.about_us_content, "right_url", $$v)},expression:"about_us_content.right_url"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-file-input',{attrs:{"accept":"image/* //","rules":[!!_vm.left_image_1 || 'Required.', _vm.rules.size],"placeholder":"Pick an image","input":_vm.loadLeftImage_1(),"label":"Image","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},model:{value:(_vm.about_us_content.left_image_1),callback:function ($$v) {_vm.$set(_vm.about_us_content, "left_image_1", $$v)},expression:"about_us_content.left_image_1"}}),_c('div',{staticStyle:{"width":"100%"}},[(_vm.left_image_1)?_c('img',{staticClass:"mb-4",attrs:{"src":_vm.getImage(_vm.left_image_1),"height":"100"}}):_vm._e()]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"SEO - Left image 1 alt"},model:{value:(_vm.about_us_content.left_alt_image_1),callback:function ($$v) {_vm.$set(_vm.about_us_content, "left_alt_image_1", $$v)},expression:"about_us_content.left_alt_image_1"}},'v-text-field',attrs,false),on))]}}],null,false,4193826466)},[_c('span',[_vm._v("The Alt tags are used to describe the image to "),_c('br'),_vm._v("search engines (cannot be seen by the user). "),_c('br'),_vm._v("Try to create a simple description of the image "),_c('br'),_vm._v("as if you were describing it to someone with their eyes closed. ")])]),_c('v-text-field',{attrs:{"label":"Left image 1 title"},model:{value:(_vm.about_us_content.left_image_title_1),callback:function ($$v) {_vm.$set(_vm.about_us_content, "left_image_title_1", $$v)},expression:"about_us_content.left_image_title_1"}}),_c('v-file-input',{attrs:{"accept":"image/* //","rules":[!!_vm.left_image_2 || 'Required.', _vm.rules.size],"placeholder":"Pick an image","input":_vm.loadLeftImage_2(),"label":"Image","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},model:{value:(_vm.about_us_content.left_image_2),callback:function ($$v) {_vm.$set(_vm.about_us_content, "left_image_2", $$v)},expression:"about_us_content.left_image_2"}}),_c('div',{staticStyle:{"width":"100%"}},[(_vm.left_image_2)?_c('img',{staticClass:"mb-4",attrs:{"src":_vm.getImage(_vm.left_image_2),"height":"100"}}):_vm._e()]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"SEO - Left image 2 alt"},model:{value:(_vm.about_us_content.left_alt_image_2),callback:function ($$v) {_vm.$set(_vm.about_us_content, "left_alt_image_2", $$v)},expression:"about_us_content.left_alt_image_2"}},'v-text-field',attrs,false),on))]}}],null,false,2487805346)},[_c('span',[_vm._v("The Alt tags are used to describe the image to "),_c('br'),_vm._v("search engines (cannot be seen by the user). "),_c('br'),_vm._v("Try to create a simple description of the image "),_c('br'),_vm._v("as if you were describing it to someone with their eyes closed. ")])]),_c('v-text-field',{attrs:{"label":"Left image 2 title"},model:{value:(_vm.about_us_content.left_image_title_2),callback:function ($$v) {_vm.$set(_vm.about_us_content, "left_image_title_2", $$v)},expression:"about_us_content.left_image_title_2"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Title"},model:{value:(_vm.about_us_content.right_title_2),callback:function ($$v) {_vm.$set(_vm.about_us_content, "right_title_2", $$v)},expression:"about_us_content.right_title_2"}}),(_vm.editorReady)?_c('editor',{staticStyle:{"width":"100%"},attrs:{"api-key":"2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb","disabled":false,"initial-value":"","init":{
                        images_upload_url: (_vm.baseURL + "/api/image-upload"),
                        height: 500,
                        menubar: false,
                        document_base_url: (_vm.baseURL + "/"),
                        relative_urls: false,
                        remove_script_host: true,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount' ],
                        toolbar:
                          'undo redo | formatselect | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help \
                          link \
                          media image',
                      }},model:{value:(_vm.about_us_content.right_description_2),callback:function ($$v) {_vm.$set(_vm.about_us_content, "right_description_2", $$v)},expression:"about_us_content.right_description_2"}}):_vm._e()],1)],1),_c('v-row',[_c('v-text-field',{attrs:{"label":"Title"},model:{value:(_vm.about_us_content.section_1_title),callback:function ($$v) {_vm.$set(_vm.about_us_content, "section_1_title", $$v)},expression:"about_us_content.section_1_title"}}),(_vm.editorReady)?_c('editor',{staticStyle:{"width":"100%"},attrs:{"api-key":"2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb","disabled":false,"initial-value":"","init":{
                      images_upload_url: (_vm.baseURL + "/api/image-upload"),
                      height: 500,
                      menubar: false,
                      document_base_url: (_vm.baseURL + "/"),
                      relative_urls: false,
                      remove_script_host: true,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount' ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help \
                        link \
                        media image',
                    }},model:{value:(_vm.about_us_content.section_1_description),callback:function ($$v) {_vm.$set(_vm.about_us_content, "section_1_description", $$v)},expression:"about_us_content.section_1_description"}}):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-file-input',{attrs:{"accept":"image/* //","rules":[!!_vm.left_image_3 || 'Required.', _vm.rules.size],"placeholder":"Pick an image","input":_vm.loadLeftImage_3(),"label":"Image","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},model:{value:(_vm.about_us_content.left_image_3),callback:function ($$v) {_vm.$set(_vm.about_us_content, "left_image_3", $$v)},expression:"about_us_content.left_image_3"}}),_c('div',{staticStyle:{"width":"100%"}},[(_vm.left_image_3)?_c('img',{staticClass:"mb-4",attrs:{"src":_vm.getImage(_vm.left_image_3),"height":"100"}}):_vm._e()]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"SEO - Left image 3 alt"},model:{value:(_vm.about_us_content.left_alt_image_3),callback:function ($$v) {_vm.$set(_vm.about_us_content, "left_alt_image_3", $$v)},expression:"about_us_content.left_alt_image_3"}},'v-text-field',attrs,false),on))]}}],null,false,3834736290)},[_c('span',[_vm._v("The Alt tags are used to describe the image to "),_c('br'),_vm._v("search engines (cannot be seen by the user). "),_c('br'),_vm._v("Try to create a simple description of the image "),_c('br'),_vm._v("as if you were describing it to someone with their eyes closed. ")])]),_c('v-textarea',{attrs:{"label":"Blue badge text"},model:{value:(_vm.about_us_content.left_description_1),callback:function ($$v) {_vm.$set(_vm.about_us_content, "left_description_1", $$v)},expression:"about_us_content.left_description_1"}}),_c('v-text-field',{attrs:{"label":"Blue badge URL"},model:{value:(_vm.about_us_content.blue_badge_url),callback:function ($$v) {_vm.$set(_vm.about_us_content, "blue_badge_url", $$v)},expression:"about_us_content.blue_badge_url"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.editorReady)?_c('editor',{staticStyle:{"width":"100%"},attrs:{"api-key":"2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb","disabled":false,"initial-value":"","init":{
                        images_upload_url: (_vm.baseURL + "/api/image-upload"),
                        height: 500,
                        menubar: false,
                        document_base_url: (_vm.baseURL + "/"),
                        relative_urls: false,
                        remove_script_host: true,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount' ],
                        toolbar:
                          'undo redo | formatselect | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help \
                          link \
                          media image',
                      }},model:{value:(_vm.about_us_content.right_description_3),callback:function ($$v) {_vm.$set(_vm.about_us_content, "right_description_3", $$v)},expression:"about_us_content.right_description_3"}}):_vm._e()],1)],1),_c('v-row',[_c('v-text-field',{attrs:{"label":"Vimeo link (video ID only - https://vimeo.com/518793614 -> 518793614)"},model:{value:(_vm.about_us_content.vimeo_url),callback:function ($$v) {_vm.$set(_vm.about_us_content, "vimeo_url", $$v)},expression:"about_us_content.vimeo_url"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"label":"Title"},model:{value:(_vm.about_us_content.left_title),callback:function ($$v) {_vm.$set(_vm.about_us_content, "left_title", $$v)},expression:"about_us_content.left_title"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.editorReady)?_c('editor',{staticStyle:{"width":"100%"},attrs:{"api-key":"2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb","disabled":false,"initial-value":"","init":{
                        images_upload_url: (_vm.baseURL + "/api/image-upload"),
                        height: 500,
                        menubar: false,
                        document_base_url: (_vm.baseURL + "/"),
                        relative_urls: false,
                        remove_script_host: true,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount' ],
                        toolbar:
                          'undo redo | formatselect | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help \
                          link \
                          media image',
                      }},model:{value:(_vm.about_us_content.left_description_2),callback:function ($$v) {_vm.$set(_vm.about_us_content, "left_description_2", $$v)},expression:"about_us_content.left_description_2"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-file-input',{attrs:{"accept":"image/* //","rules":[!!_vm.image_down || 'Required.', _vm.rules.size],"placeholder":"Pick an image","input":_vm.loadDownImage(),"label":"Image","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},model:{value:(_vm.about_us_content.image_down),callback:function ($$v) {_vm.$set(_vm.about_us_content, "image_down", $$v)},expression:"about_us_content.image_down"}}),_c('div',{staticStyle:{"width":"100%"}},[(_vm.image_down)?_c('img',{staticClass:"mb-4",attrs:{"src":_vm.getImage(_vm.image_down),"height":"100"}}):_vm._e()])],1)],1),_c('v-row',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"SEO - Page title (shown in browser tab)"},model:{value:(_vm.about_us_content.tab_title),callback:function ($$v) {_vm.$set(_vm.about_us_content, "tab_title", $$v)},expression:"about_us_content.tab_title"}},'v-text-field',attrs,false),on))]}}],null,false,1078497686)},[_c('span',[_vm._v("SEO Page title - This is the title of the tab of the page and also shows up "),_c('br'),_vm._v("in search engines as the main title for the page. "),_c('br'),_vm._v("Keep the title short (max. 60 characters) and "),_c('br'),_vm._v("utilise keywords from the page. ")])])],1),_c('v-row',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-textarea',_vm._g(_vm._b({attrs:{"label":"SEO - Meta description (this description will appear on google search results)"},model:{value:(_vm.about_us_content.og_meta_desc),callback:function ($$v) {_vm.$set(_vm.about_us_content, "og_meta_desc", $$v)},expression:"about_us_content.og_meta_desc"}},'v-textarea',attrs,false),on))]}}],null,false,3332970390)},[_c('span',[_vm._v("The meta description is shown in search engines and "),_c('br'),_vm._v("is used to describe the contents of the page. "),_c('br'),_vm._v("Keep it between 50-160 characters and try to "),_c('br'),_vm._v("imagine what a user would search for in order "),_c('br'),_vm._v("to find the contents of the page. ")])])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(_vm.successfullyUpdated)?_c('span',{staticStyle:{"color":"green","margin-right":"10px"}},[_vm._v("Successfully updated!")]):_vm._e(),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit"}},[_vm._v("Save")])],1)],1)],1):_vm._e()],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }